/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-right-arrow:before { content: "\f100"; }
.flaticon-claim:before { content: "\f101"; }
.flaticon-lock:before { content: "\f102"; }
.flaticon-diagram:before { content: "\f103"; }
.flaticon-money:before { content: "\f104"; }
.flaticon-pen:before { content: "\f105"; }
.flaticon-refresh:before { content: "\f106"; }
.flaticon-budget:before { content: "\f107"; }
.flaticon-data:before { content: "\f108"; }
.flaticon-file:before { content: "\f109"; }
.flaticon-ruler:before { content: "\f10a"; }
.flaticon-facebook:before { content: "\f10b"; }
.flaticon-twitter:before { content: "\f10c"; }
.flaticon-linkedin:before { content: "\f10d"; }
.flaticon-instagram-logo:before { content: "\f10e"; }