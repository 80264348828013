	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/01/2020 10:22
  	*/

@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/style-two/Flaticon.eot");
	src: url("../../assets/fonts/style-two/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/style-two/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/style-two/Flaticon.woff") format("woff"),
	url("../../assets/fonts/style-two/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/style-two/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/style-two/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-industry:before { content: "\f100"; }
.flaticon-group:before { content: "\f101"; }
.flaticon-promotion:before { content: "\f102"; }
.flaticon-coffee-cup:before { content: "\f103"; }