:root{
    --body-color: #f5f5f5;
    --footer-background-color:rgb(39, 89, 197);
}

body{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: var(--body-color);
}